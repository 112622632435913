export const subTableProperties2 = [
  {
    label: '',
    prop: 'pricing_name',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true
  },
  {
    label: '成本价',
    prop: 'pricing_price',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: {
      type: 'input',
      required: false,
      disabled: false
    }
  },
  {
    label: '税点',
    prop: 'tax_points',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: {
      type: 'input',
      required: false,
      disabled: false
    }
  },
  {
    label: '含税金额',
    prop: 'includtax_money',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true
  },
  {
    label: '税额合计',
    prop: 'taxamount_total',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true
  }
];
export const subTableProperties = [
  { label: '', prop: 'pricing_name', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '成本价', prop: 'pricing_price', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '税点', prop: 'tax_points', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '含税金额', prop: 'includtax_money', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '税额合计', prop: 'taxamount_total', itemType: 'input', input: false, sortable: false, widthAuto: true }
];
