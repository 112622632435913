<template>
  <div class="consultPartList">
    <div class="vg_mb_16" v-if="$cookies.get('userInfo').user_id === 87">
      <!--      <el-button type="primary" size="small" @click="copyPart()">复制</el-button>-->
      <!--      <el-button size="small" type="danger" @click="deletePart()">删除</el-button>-->
      <el-button size="small" type="primary" @click="informDyj()">通知DYJ完善信息</el-button>
      <div style="display: inline-block">(TIPS:不勾选则默认全部通知)</div>
    </div>
    <el-table
      ref="consultPartTable"
      :header-cell-style="{ 'text-align': 'center' }"
      tooltip-effect="dark"
      border
      :data="consultFrom.consult_part_list"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="48" align="center"></el-table-column>
      <el-table-column label="序号" width="48" align="center">
        <template v-slot="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column width="120" label="产品图片" prop="imge_url">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'consult_part_list.' + scope.$index + '.imge_url'">
            <el-image
              v-if="scope.row.imge_url.indexOf('aliyun') !== -1"
              :src="scope.row.imge_url"
              fit="contain"
              :previewSrcList="[scope.row.imge_url]"
              placeholder="暂无产品图片"
            >
            </el-image>
            <span v-else>暂无</span>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="120" label="我司货号" prop="prod_no" sortable>
        <template slot="header"><span class="vg_deep_red">我司货号</span></template>
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'consult_part_list.' + scope.$index + '.prod_no'">
            <el-input v-model="scope.row.prod_no" show-word-limit disabled placeholder="暂无我司货号"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="120" label="更改号" prop="prod_suffix">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'consult_part_list.' + scope.$index + '.prod_suffix'">
            <el-input v-model="scope.row.prod_suffix" show-word-limit disabled placeholder="暂无更改号"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="120" label="客户货号" prop="prod_cust_no">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'consult_part_list.' + scope.$index + '.prod_cust_no'">
            <el-input v-model="scope.row.prod_cust_no" show-word-limit disabled placeholder="暂无客户货号"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="120" label="询价人" prop="scon_stff_name">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'consult_part_list.' + scope.$index + '.scon_stff_id'">
            <el-input v-model="scope.row.scon_stff_name" show-word-limit disabled placeholder="暂无询价人"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="120" label="中文名称" prop="prod_name">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'consult_part_list.' + scope.$index + '.prod_name'">
            <el-input v-model="scope.row.prod_name" show-word-limit disabled placeholder="暂无中文名称"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="120" label="部件名称" prop="prod_partno">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'consult_part_list.' + scope.$index + '.prod_partno'">
            <el-input v-model="scope.row.prod_partno" show-word-limit maxlength="20" disabled placeholder="暂无部件名称"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="120" label="是否主商品" prop="prod_partno">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'consult_part_list.' + scope.$index + '.prod_mainsup'">
            <el-input :value="scope.row.prod_mainsup === 1 ? '是' : '否'" show-word-limit maxlength="20" disabled placeholder="暂无部件名称"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="120" label="包装/送货要求" prop="pack_ement">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'consult_part_list.' + scope.$index + '.pack_ement'">
            <el-tooltip class="item" effect="light" :content="scope.row.pack_ement" placement="top-start">
              <el-input v-model="scope.row.pack_ement" show-word-limit disabled placeholder="暂无包装要求"></el-input>
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="120" label="订单数量" prop="prod_num">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'consult_part_list.' + scope.$index + '.prod_num'">
            <el-input v-model="scope.row.prod_num" show-word-limit disabled placeholder="暂无订单数量"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="130" label="要求交期" prop="ask_time">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'consult_part_list.' + scope.$index + '.ask_time'">
            <el-date-picker v-model="scope.row.ask_time" show-word-limit type="date" disabled placeholder="暂无要求交期"></el-date-picker>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="120" label="外销备注" prop="sale_remark">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'consult_part_list.' + scope.$index + '.export_remark'">
            <el-tooltip class="item" effect="light" :content="scope.row.export_remark" placement="top-start">
              <el-input v-model="scope.row.export_remark" show-word-limit disabled placeholder="暂无外销备注"></el-input>
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="120" label="采购备注" prop="purch_remark">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'consult_part_list.' + scope.$index + '.purch_remark'">
            <el-tooltip class="item" effect="light" :content="scope.row.purch_remark" placement="top-start">
              <el-input v-model="scope.row.purch_remark" show-word-limit placeholder="暂无采购备注"></el-input>
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="120" label="主商品工厂" prop="main_supp">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'consult_part_list.' + scope.$index + '.main_supp'">
            <el-tooltip class="item" effect="light" :content="scope.row.main_supp" placement="top-start">
              <el-input disabled v-model="scope.row.main_supp" show-word-limit placeholder="暂无主商品工厂"></el-input>
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>
      <!--      <el-table-column width="140" label="负责台面" prop="duty_stff_ids" v-if="consultFrom.dept_name === '采购二部'">-->
      <!--        <template v-slot="scope">-->
      <!--          <el-select size="small" v-model="scope.row.duty_stff_ids" multiple filterable placeholder="请选择">-->
      <!--            <el-option v-for="item in stffList" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"></el-option>-->
      <!--          </el-select>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column width="450" label="询价工厂 / 价格 / 沟通交期" prop="inquiryFactory">
        <template slot="header">
          <span class="vg_deep_red">询价工厂 / 价格 / 沟通交期</span>
        </template>
        <template v-slot="scope">
          <el-col :span="20" v-for="(item, index) in scope.row['tempSize']" :key="`tempSize${index}`">
            <div style="display: flex">
              <el-select
                class="vg_mr_8"
                size="mini"
                filterable
                v-model="scope.row['tempSupp'][index]"
                placeholder="请选择工厂"
                clearable
                @change="addRqtaPricingList(scope.row.consult_part_id, scope.row['tempSupp'][index], scope.row.inquiry_part_id)"
              >
                <el-option
                  v-for="item in suppList"
                  :key="`tempSize${item.supp_id}${index}`"
                  :label="item.supp_abbr + '(' + item.supp_no + ')'"
                  :value="{ value: item.supp_id, label: item.supp_abbr }"
                />
              </el-select>
              <el-tooltip class="item" effect="light" :content="scope.row['tempPrice'][index]" placement="top-start">
                <el-input
                  size="mini"
                  style="width: 320px"
                  @input="val => (scope.row['tempPrice'][index] = consultFrom.dept_name === '采购一部' ? keep4Decimal(val) : val)"
                  v-model="scope.row['tempPrice'][index]"
                  :maxlength="consultFrom.dept_name === '采购一部' ? 9 : 200"
                  show-word-limit
                  clearable
                  placeholder="请填写价格"
                />
              </el-tooltip>
              <el-date-picker size="mini" value-format="timestamp" v-model="scope.row['tempTime'][index]" type="date" placeholder="选择日期"></el-date-picker>
            </div>
          </el-col>
          <el-button
            icon="el-icon-plus"
            circle
            size="mini"
            @click="
              () => {
                scope.row['tempSize'] = scope.row['tempSize'] + 1;
                scope.row['tempSupp'].push({ value: null, label: null });
              }
            "
          ></el-button>
        </template>
      </el-table-column>
      <el-table-column width="120" label="采购确认工厂" prop="supp_id">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'consult_part_list.' + scope.$index + '.supp_id'">
            <el-select
              v-model="scope.row.supp_id"
              placeholder="暂无采购确认工厂"
              size="small"
              clearable
              filterable
              @change="
                val => {
                  editSuppAbbrAndpurcStffId(val, scope.$index);
                }
              "
            >
              <el-option v-for="item in scope.row['tempSupp']" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="120" label="采购确认价" prop="ack_price">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'consult_part_list.' + scope.$index + '.ack_price'">
            <el-input
              v-model="scope.row.ack_price"
              show-word-limit
              maxlength="9"
              @input="val => (scope.row.ack_price = keep4Decimal(val))"
              placeholder="暂无采购确认价"
              clearable
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column width="150" label="确认交期" prop="table_time">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'consult_part_list.' + scope.$index + '.table_time'">
            <el-date-picker v-model="scope.row.table_time" value-format="timestamp" show-word-limit type="date" placeholder="沟通交期"></el-date-picker>
          </el-form-item>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import helper from '@assets/js/helper';
import { BigNumber } from 'bignumber.js';
import { keep4Decimal, keepNumber } from '@assets/js/regExUtil';
import { get } from '@api/request';
import { ConsultAPI } from '@api/modules/consult';

export default {
  name: 'ConsultPartList',
  computed: {
    BigNumber() {
      return BigNumber;
    },
    helper() {
      return helper;
    }
  },
  props: {
    consultFrom: {
      type: Object
    },
    suppList: {
      type: Array
    },
    deptList: {
      type: Array
    },
    logUser: {
      type: Object
    },
    department: {
      type: Object
    },
    destroyFlag: {
      type: Array
    },
    stffList: {
      type: Array
    }
  },
  data() {
    return {
      selectionsList: []
    };
  },
  created() {},
  methods: {
    keep4Decimal,
    keepNumber,
    tableRowClassName({ row, rowIndex }) {
      //如果子表负责台面里有登录人则变色
      if (row.duty_stff_ids && row.duty_stff_ids.includes(this.logUser.stff_id)) {
        return 'success-row';
      }
      return '';
    },
    addRqtaPricingList(consult_part_id, supp, inquiry_part_id) {
      let length = this.consultFrom.rqtaPricingList.filter(x => x.consullt_part_supp_id === supp.value && x.consult_part_id === consult_part_id).length;
      //判断供应商是否已经添加过工厂核价
      if (length <= 0) {
        let rqtaPricingList = this.consultFrom.consult_part_list.find(x => x.consult_part_id === consult_part_id).rqtaPricingList;
        let computedSubTableData = this.consultFrom.consult_part_list.find(x => x.consult_part_id === consult_part_id).rqtaProjet; //最终核价
        //默认等于DYJ核价
        rqtaPricingList.forEach(item => {
          this.consultFrom.rqtaPricingList.push({
            quta_pricing_id: null,
            quta_source: 3,
            consult_part_id: consult_part_id,
            consullt_part_supp_id: supp.value,
            inquiry_part_id: inquiry_part_id,
            quta_id: item.quta_id,
            inquiry_id: item.inquiry_id,
            pricing_name: item.pricing_name,
            pricing_price: item.pricing_price,
            tax_points: item.tax_points,
            includtax_money: item.includtax_money,
            taxamount_total: item.taxamount_total
          });
        });
        //默认等于DYJ核价
        let rqtaProjet = cloneDeep(computedSubTableData);
        rqtaProjet.quta_project_id = null;
        rqtaProjet.consult_part_id = consult_part_id;
        rqtaProjet.consullt_part_supp_id = supp.value;
        this.consultFrom.rqtaProjetList.push(rqtaProjet);
      }
    },
    //复制
    copyPart() {
      if (this.selectionsList.length === 0) {
        this.$message({
          type: 'error',
          message: '至少选择一条数据！'
        });
        return;
      }
      if (this.selectionsList.length > 1) {
        this.$message({
          type: 'error',
          message: '只能选择一条数据！'
        });
        return;
      }
      this.$prompt('请确认复制数量', '复制', {
        confirmButtonText: '确定复制',
        cancelButtonText: '取消',
        inputPattern: /^[\d.]+$/,
        inputErrorMessage: '请输入正确的数量',
        inputValue: 5
      }).then(({ value }) => {
        if (value > 20) {
          this.$message({
            type: 'error',
            message: '单次只能复制20份！'
          });
          return;
        }
        for (let i = 0; i < value; i++) {
          let consultPartClone = cloneDeep(this.selectionsList);
          consultPartClone.forEach(item => {
            item.consult_part_id = 0;
            item.if_factory = '';
            item.only_tag = 0;
          });
          this.consultFrom.consult_part_list = this.consultFrom.consult_part_list.concat(consultPartClone);
        }
        //key值重置
        this.reductionKey();
      });
    },
    //通知DYJ完善信息
    informDyj() {
      let consult_part_ids = [];
      if (this.selectionsList.length > 0) {
        this.selectionsList.forEach(item => {
          consult_part_ids.push(item.consult_part_id);
        });
      } else {
        this.consultFrom.consult_part_list.forEach(item => {
          consult_part_ids.push(item.consult_part_id);
        });
      }
      let ids = consult_part_ids.join(',');
      get(ConsultAPI.informDyjRepair, { inquiry_id: this.consultFrom.inquiry_id, consult_part_ids: ids })
        .then(({ data }) => {
          if (data.code === 0) {
            return this.$message.success('通知成功！');
          } else {
            return this.$message.error('通知失败！');
          }
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //删除
    deletePart() {
      if (this.selectionsList.length === 0) {
        this.$message({
          type: 'error',
          message: '至少选择一条数据！'
        });
        return;
      }
      this.$confirm('确定删除？但会保留原始数据！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // if (this.consultFrom.consult_part_list.length === this.selectionsList.length) {
          //   return this.$message.error('不能删除全部数据！');
          // }
          // if (this.selectionsList.length > 1) {
          //   return this.$message.error('不可多选删除！');
          // }
          // let isOk = false;
          // this.selectionsList.forEach((item, index) => {
          // let count = this.consultFrom.consult_part_list.filter(item1 => item1.only_tag === item.only_tag).length;
          // if (count <= 1) {
          //   isOk = true;
          //   return false;
          // }
          // });
          // if (isOk) {
          //   return this.$message.error('不能删除唯一数据！');
          // }
          //不删除唯一数据start
          let only = [];
          for (let i = 0; i < this.selectionsList.length; i++) {
            for (let j = 0; j < this.consultFrom.consult_part_list.length; j++) {
              if (this.selectionsList[i].only_tag && this.selectionsList[i].only_tag === this.consultFrom.consult_part_list[j].only_tag) {
                only.push(j);
              }
            }
          }
          only = only.sort((a, b) => {
            return b - a;
          });
          for (let i of only) {
            this.selectionsList.splice(i, 1);
          }
          //end
          //删除start
          let destroyList = [];
          this.selectionsList.forEach(item => {
            if (item.consult_part_id) {
              item.destroy_flag = 1;
              destroyList.push(cloneDeep(item));
            }
          });
          this.$emit('updateDestroyFlag', destroyList);
          // this.consultFrom.consult_part_list = this.consultFrom.consult_part_list.filter(item =>
          //   this.selectionsList.every(item1 => item.consult_part_id !== item1.consult_part_id)
          // );
          let temp = [];
          for (let i = 0; i < this.selectionsList.length; i++) {
            for (let j = 0; j < this.consultFrom.consult_part_list.length; j++) {
              if (this.selectionsList[i].key === this.consultFrom.consult_part_list[j].key) {
                temp.push(j);
              }
            }
          }
          temp = temp.sort((a, b) => {
            return b - a;
          });
          for (let i of temp) {
            this.consultFrom.consult_part_list.splice(i, 1);
          }
          //end
          //key值重置
          this.reductionKey();
          //关闭多选框
          this.$refs.consultPartTable.clearSelection();
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
          this.$refs.consultPartTable.clearSelection();
        });
    },
    // key值重置
    reductionKey() {
      for (let i = 0; i < this.consultFrom.consult_part_list.length; i++) {
        this.consultFrom.consult_part_list[i].key = Number(i);
      }
    },
    editSuppAbbrAndpurcStffId(val, index) {
      if (this.consultFrom.dept_name === '采购一部') {
        //联动采购确认价格
        this.consultFrom.consult_part_list[index]['tempSupp'].forEach((item, index2) => {
          if (val === item.value) {
            this.consultFrom.consult_part_list[index].ack_price = this.consultFrom.consult_part_list[index]['tempPrice'][index2];
            this.consultFrom.consult_part_list[index].table_time = this.consultFrom.consult_part_list[index]['tempTime'][index2];
          }
        });
      }
    },
    //供应商，工厂，对应台面联动删除方法
    clearAbbrSttf(index) {
      this.consultFrom.consult_part_list[index].supp_id = null;
      this.consultFrom.consult_part_list[index].supp_no = null;
      this.consultFrom.consult_part_list[index].supp_abbr = null;
      this.consultFrom.consult_part_list[index].stff_id = null;
    },
    //选择
    handleSelectionChange(selection) {
      this.selectionsList = selection;
    },
    //采购二部只可更改自己的数据
    checkDeptAndStff(val) {
      if (this.logUser.dept_id === 7) {
        return this.logUser.stff_id !== val;
      }
    },
    //确认交期联动方法
    updateAckTime(index) {
      this.consultFrom.consult_part_list[index].ack_time = this.consultFrom.consult_part_list[index].table_time;
    },
    //确认价格联动方法
    updateQuotePrice(index) {
      this.consultFrom.consult_part_list[index].ack_price = this.consultFrom.consult_part_list[index].quote_price;
    },
    //对应台面非登录人则不可以更改台面报价
    checkDeptAndStff2(val) {
      if (this.logUser.stff_id !== val) {
        return true;
      }
    },
    //只有经理和核价人可以更改供应商和工厂
    checkDeptAndStffByDept2(val) {
      return !(this.logUser.stff_id === this.department.stff_id || this.logUser.stff_id === val);
    },
    //登录人为核价人则可以更改全部的核价报价
    checkDeptAndStff3(val) {
      return this.logUser.stff_id !== val;
    },
    // select变化提示
    selectChange(val, index) {
      let { prod_no: number } = this.consultFrom.consult_part_list[index];
      let { prod_partno: number1 } = this.consultFrom.consult_part_list[index];
      let filter = this.consultFrom.consult_part_list
        .filter(({ prod_no }) => prod_no === number)
        .filter(({ prod_partno }) => prod_partno === number1)
        .filter(({ if_factory }) => if_factory === 1);
      if (filter.length > 1) {
        this.consultFrom.consult_part_list[index].if_factory = null;
        return this.$message.warning('同一我司货号存在多个确认工厂,请修改');
      }
    }
  }
};
</script>

<style>
.el-table .success-row {
  background: #f0fff0;
}
</style>
