<template>
  <div class="vg_wrapper">
    <div class="vd_edhea vg_button_group">
      <el-row class="priceConsult_header">
        <el-col>
          <editHeader
            :isShow="isShow"
            :btn="btn"
            :special="true"
            :strForm="consultFrom"
            @openEdit="openEdit"
            @closeEdit="closeEdit"
            @submit="submit('consultFromRef')"
          />
          <div class="vd_export" v-if="isShow">
            <el-button type="primary" size="small" @click="buttonRefresh">刷新</el-button>
            <el-button size="small" type="success" @click="distribute" v-if="consultFrom.table_price_status === 0"> 一键下发 </el-button>
            <el-button size="small" type="primary" v-if="consultFrom.dept_name === '采购二部' && consultFrom.table_price_status !== 0" @click="subToBoss">
              提交
            </el-button>
          </div>
          <div class="vd_export2 vg_mr_8">
            <span style="margin-right: 2px">询价单编号:</span>
            <el-input size="mini" style="width: 150px" disabled v-model="consultFrom.consult_no" show-word-limit></el-input>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-form :model="consultFrom" size="mini" ref="consultFromRef" :rules="consultRules" label-width="120px" :disabled="isShow">
      <el-row class="vg_mt_8">
        <el-col :md="5">
          <el-form-item label="客户简称:" prop="cust_abbr">
            <el-input v-model="consultFrom.cust_abbr" placeholder="暂无客户简称" disabled show-word-limit clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="5">
          <el-form-item label="系列名称:" prop="series_name">
            <el-input v-model="consultFrom.series_name" placeholder="暂无系列名称" disabled show-word-limit clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="5">
          <el-form-item label="销售部门:" prop="scon_dept_name">
            <el-input v-model="consultFrom.scon_dept_name" placeholder="暂无销售部门" disabled clearable filterable>
              <el-option v-for="item in deptList" :key="item.dept_id" :label="item.dept_name" :value="item.dept_id"></el-option>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="5">
          <el-form-item label="核价人:" prop="price_id">
            <el-select v-model="consultFrom.price_id" placeholder="请选择核价人" size="small" clearable filterable>
              <el-option v-for="item in stffList" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <div style="text-align: center; float: right; margin-right: 30px">
          报价状态:
          <el-tag :type="getPriceStatusName(consultFrom.table_price_status).type">
            {{ getPriceStatusName(consultFrom.table_price_status).name }}
          </el-tag>
        </div>
      </el-row>
      <el-row class="vg_mt_8">
        <el-col :md="20">
          <el-form-item label="外销备注:" prop="consult_remark">
            <el-input v-model="consultFrom.consult_remark" type="textarea" rows="3" placeholder="请输入" maxlength="255" show-word-limit clearable disabled>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_8" v-if="consultFrom.dept_name === '采购二部' && consultFrom.table_price_status !== 0">
        <div v-for="item in subItemList">
          <div style="width: 70px; display: inline-block">{{ item.subStffName }}</div>
          ：
          <el-tag :type="item.subStatus === '已提交' ? 'success' : 'info'">{{ item.subStatus }}</el-tag>
        </div>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <div class="vd_button_group vg_mtb_16">
            <el-button type="success" size="small" @click="exportPartList" :disabled="false">导出excel</el-button>
          </div>
          <el-tabs v-model="activeName" type="border-card">
            <el-tab-pane label="部件信息" name="first" :key="'first'">
              <ConsultPartList
                ref="ConsultPartList"
                :stffList="stffList"
                :consultFrom="consultFrom"
                :department="department"
                :deptList="deptList"
                :suppList="suppList"
                :logUser="logUser"
                :destroyFlag="destroyFlag"
                @updateDestroyFlag="updateDestroyFlag"
              ></ConsultPartList>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
    </el-form>
    <el-row class="vg_mt_16 vd_bortop" v-if="isItAProcurementDepartment">
      <el-col>
        <div style="padding-top: 10px">
          <span style="display: inline-block">我司货号：</span>
          <el-select
            @change="getDyjData"
            :disabled="isShow"
            style="display: inline-block; width: 200px"
            v-model="consult_part_id"
            size="small"
            clearable
            filterable
            placeholder="请选择"
            @clear="clearDyjData"
          >
            <el-option
              v-for="item in prodNoList"
              :key="item.consult_part_id"
              :label="item.prod_no + '-' + item.prod_partno"
              :value="item.consult_part_id"
            ></el-option>
          </el-select>
        </div>
      </el-col>
      <h4>DYJ核价汇总</h4>
      <el-row :gutter="100">
        <el-col :md="16">
          <DynamicUTable
            :auto-height="true"
            ref="dynamicUTable3"
            :tableData="subTableData"
            :columns="subTableProperties"
            :need-pagination="false"
            :need-check-box="false"
            :show-summary="true"
            :need-all-column-summary="true"
          >
          </DynamicUTable>
        </el-col>
        <el-col :md="8">
          <table border="1px" width="100%" cellpadding="0" cellspacing="0" class="vd_table">
            <tr>
              <th></th>
              <th style="width: 70%">金额</th>
            </tr>
            <tr>
              <td>利润百分比</td>
              <td>{{ computedSubTableData.profit_percentage }}</td>
            </tr>
            <tr>
              <td>样品利润金额</td>
              <td>{{ computedSubTableData.profit_money }}</td>
            </tr>
            <tr>
              <td>含税价格</td>
              <td>{{ computedSubTableData.includtax_money }}</td>
            </tr>
            <tr>
              <td>毛利率</td>
              <td>{{ computedSubTableData.gross_margin }}</td>
            </tr>
          </table>
        </el-col>
      </el-row>
      <h4 style="padding-top: 10px">工厂核价汇总</h4>
      <el-row>
        <el-col :span="24">
          <div style="padding-bottom: 10px">
            <el-select
              @change="getAuditSummary"
              style="display: inline-block; width: 200px"
              v-model="supp_id"
              :disabled="isShow"
              size="small"
              clearable
              filterable
              placeholder="请选择"
            >
              <el-option v-for="item in partSuppList" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="100">
        <el-col :md="16">
          <DynamicUTable
            :auto-height="true"
            ref="dynamicUTable3"
            :tableData="subTableData2"
            :columns="subTableProperties2"
            :need-pagination="false"
            :need-check-box="false"
            :show-summary="true"
            :need-all-column-summary="true"
          >
            <template v-slot:pricing_price="scope">
              <el-form-item label-width="0" :prop="'subTableData2.' + scope.$index + '.pricing_price'">
                <el-input
                  v-model.trim="subTableData2[scope.$index].pricing_price"
                  maxlength="15"
                  show-word-limit
                  placeholder="暂无成本价"
                  @input="val => (subTableData2[scope.$index].pricing_price = keep4Decimal(val))"
                  @change="changePricing(subTableData2[scope.$index])"
                ></el-input>
              </el-form-item>
            </template>
            <template v-slot:tax_points="scope">
              <el-form-item label-width="0" :prop="'subTableData2.' + scope.$index + '.tax_points'">
                <el-input
                  v-model.trim="subTableData2[scope.$index].tax_points"
                  maxlength="9"
                  show-word-limit
                  placeholder="暂无税点"
                  @input="val => (subTableData2[scope.$index].tax_points = keep2Decimal(val))"
                  @change="changePricing(subTableData2[scope.$index])"
                ></el-input>
              </el-form-item>
            </template>
          </DynamicUTable>
        </el-col>
        <el-col :md="8">
          <table border="1px" width="100%" cellpadding="0" cellspacing="0" class="vd_table">
            <tr>
              <th></th>
              <th style="width: 70%">金额</th>
            </tr>
            <tr>
              <td>利润百分比</td>
              <td>
                <el-input
                  v-model="computedSubTableData2.profit_percentage"
                  show-word-limit
                  maxlength="15"
                  @input="val => (computedSubTableData2.profit_percentage = keep4Decimal(val))"
                  placeholder="暂无利润百分比"
                  clearable
                  @change="changeProject"
                ></el-input>
              </td>
            </tr>
            <tr>
              <td>样品利润金额</td>
              <td>{{ computedSubTableData2.profit_money }}</td>
            </tr>
            <tr>
              <td>含税价格</td>
              <td>{{ computedSubTableData2.includtax_money }}</td>
            </tr>
            <tr>
              <td>毛利率</td>
              <td>{{ computedSubTableData2.gross_margin }}</td>
            </tr>
          </table>
        </el-col>
      </el-row>
      <el-col>
        <div style="padding-top: 10px">DYJ核价明细</div>
      </el-col>
      <el-col>
        <el-tabs v-model="activeName2" class="vd_eltab vd_buttom">
          <el-tab-pane label="原材料" name="first" :key="'first'">
            <DynamicUTable
              ref="otherTable1"
              :tableData="otherTableData1"
              :columns="otherTableProperties1"
              :need-pagination="false"
              :need-check-box="false"
              :is-show="isShow"
            />
            <div style="float: right">
              <span> 合计:{{ truncateDecimal(objectArrayReduce(otherTableData1, 'quta_money')) }} </span>
              <br />
              <span> 税价合计:{{ truncateDecimal(objectArrayReduce(otherTableData1, 'quta_money') * 1.05) }} </span>
            </div>
          </el-tab-pane>
          <el-tab-pane label="辅助材料" name="second" :key="'second'">
            <DynamicUTable
              ref="otherTable2"
              :tableData="otherTableData2"
              :columns="otherTableProperties2"
              :need-pagination="false"
              :need-check-box="false"
              :is-show="isShow"
            />
            <div style="float: right">
              <span> 合计:{{ truncateDecimal(objectArrayReduce(otherTableData2, 'quta_money')) }} </span>
              <br />
              <span> 税价合计:{{ truncateDecimal(objectArrayReduce(otherTableData2, 'quta_money') * 1.05) }} </span>
            </div>
          </el-tab-pane>
          <el-tab-pane label="人工工资" name="third" :key="'third'">
            <DynamicUTable
              ref="otherTable3"
              :tableData="otherTableData3"
              :columns="otherTableProperties3"
              :need-pagination="false"
              :need-check-box="false"
              :is-show="isShow"
            />
            <div style="float: right">
              <span> 合计:{{ truncateDecimal(objectArrayReduce(otherTableData3, 'money')) }} </span>
              <br />
              <span> 税价合计:{{ truncateDecimal(objectArrayReduce(otherTableData3, 'money') * 1.13) }} </span>
            </div>
          </el-tab-pane>
          <el-tab-pane label="配件信息" name="fourth" :key="'fourth'">
            <DynamicUTable
              ref="otherTable1"
              :tableData="otherTableData4"
              :columns="otherTableProperties4"
              :need-pagination="false"
              :need-check-box="false"
              :is-show="isShow"
            />
            <div style="float: right">
              <span> 合计:{{ truncateDecimal(objectArrayReduce(otherTableData4, 'quta_part_price')) }} </span>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
    <el-row class="vg_mt_16 vd_bortop">
      <el-col>
        <inputUser :isCookie="false" :stffForm="stffForm" ref="userData"></inputUser>
      </el-col>
    </el-row>
    <el-dialog title="选择下发人" :visible.sync="popUpShow" width="400px">
      <el-checkbox-group v-model="checkStffIds">
        <el-checkbox v-for="item in stffList" :label="item.stff_id" :key="item.stff_id">{{ item.stff_name }} </el-checkbox>
      </el-checkbox-group>
      <div class="vg_button" style="text-align: right">
        <el-button size="small" type="primary" @click="submitPa(checkStffIds)">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import inputUser from '@/views/component/inputUser.vue';
import editHeader from '@/views/component/editHeader.vue';
import { get, post } from '@api/request';
import { deptAPI } from '@api/modules/department';
import { ConsultAPI } from '@api/modules/consult';
import { stffAPI } from '@api/modules/staff';
import ConsultPartList from '@/views/SalesManagement/ConsultManage/TabChild/ConsultPartList.vue';
import { suppAPI } from '@api/modules/supp';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { otherTableProperties1, otherTableProperties2, otherTableProperties3, otherTableProperties4 } from '@/views/SalesManagement/InquiryManage/inquiry';
import { subTableProperties, subTableProperties2 } from './inquiry.js';
import { downloadFile, getDept } from '@api/public';
import { objectArrayReduce } from '@assets/js/arrayUtils';
import { requiredReminder } from '@assets/js/domUtils';
import { keep2Decimal, keep4Decimal } from '@assets/js/regExUtil';
import { BigNumber } from 'bignumber.js';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'ConsultEditMain',
  components: {
    DynamicUTable,
    ConsultPartList,
    inputUser,
    editHeader
  },
  data() {
    return {
      isShow: true,
      loadingFlag: false,
      btn: {},
      stffForm: {},
      activeName: 'first',
      activeName2: 'first',
      loadFlag: true,
      jumpDialogVisible: false,
      consult_part_id: '',
      supp_id: '',
      consultFrom: {
        consult_no: null, //询价单编码
        cust_id: null, //客户id
        cust_abbr: null, //客户简称
        series_name: null, //系列名称
        scon_dept_id: null, //销售部门Id
        scon_dept_name: null, //销售部门
        price_id: null, //核价人id
        price_name: null, //核价人
        table_price_status: null, //台面报价状态
        consult_remark: null, //外销备注
        status: null, //单据状态
        consult_part_list: [], //部件信息
        destroy_flag: null //删除标识字段
      },
      computedSubTableData: {
        profit_percentage: null,
        profit_money: null,
        includtax_money: null,
        gross_margin: null
      },
      computedSubTableData2: {
        profit_percentage: null,
        profit_money: null,
        includtax_money: null,
        gross_margin: null
      },
      auditSummaryList: [],
      partSuppList: [],
      subTableProperties: subTableProperties,
      subTableProperties2: subTableProperties2,
      subTableData: [],
      subTableData2: [],
      otherTableData1: [],
      otherTableData2: [],
      otherTableData3: [],
      otherTableData4: [],
      otherTableProperties1: otherTableProperties1,
      otherTableProperties2: otherTableProperties2,
      otherTableProperties3: otherTableProperties3,
      otherTableProperties4: otherTableProperties4,
      consultRules: {
        price_id: [{ required: true, message: '请选择核价人', trigger: 'blur' }],
        scon_dept_name: [{ required: true, message: '', trigger: 'blur' }]
      },
      partListCopy: null,
      stffList: [],
      deptList: [],
      subItemList: [],
      isItAProcurementDepartment: false,
      prodNoList: [],
      suppList: [], //供应商list
      department: {}, //部门经理id
      logUser: {}, //登录信息
      destroyFlag: [], //删除的部件
      popUpShow: false,
      checkStffIds: [],
      checkList: []
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    keep2Decimal,
    keep4Decimal,
    objectArrayReduce,
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    truncateDecimal(num) {
      return Math.floor(num * 10000) / 10000;
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.isShow = true;
        this.$emit('isShow', this.isShow);
        this.initData();
        this.$message.info('已撤销编辑!');
      });
    },
    //刷新
    buttonRefresh() {
      this.initData();
    },
    async initData() {
      this.getSupp();
      this.getDept();
      let currentPersonDept = this.$cookies.get('userInfo').dept_id;
      let caigou1 = await getDept('采购一部', 'ids');
      this.isItAProcurementDepartment = caigou1.includes(currentPersonDept) || currentPersonDept === 1;
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          try {
            this.$refs[formName].validate((valid, object) => (valid ? this.saveInfo() : requiredReminder(object)));
          } catch (e) {
            console.log(e);
          }
        })
        .catch(() => {
          this.$message.info('已取消保存');
        });
    },
    //导出excel
    exportPartList() {
      ConsultAPI.exportPartList({ consult_id: this.consultFrom.consult_id }).then(({ data }) => {
        downloadFile({ fileUrl: data.url, fileName: data.fileName });
      });
    },
    //询价工厂/价格
    handleSubTableData() {
      this.consultFrom.consult_part_list.forEach(item => {
        this.$set(item, 'tempPrice', item.inquiryFactoryPrice.indexOf(',') !== -1 ? item.inquiryFactoryPrice.split(',') : [item.inquiryFactoryPrice]);
        this.$set(item, 'tempTime', item.inquiryFactoryTime.indexOf(',') !== -1 ? item.inquiryFactoryTime.split(',') : [item.inquiryFactoryTime]);
        let temp = [];
        if (item.inquiryFactory.indexOf(',') !== -1) {
          let split = item.inquiryFactory.split(',');
          split.forEach(subItem => {
            if (!subItem) {
              temp.push({ value: null, label: null });
            } else {
              let { supp_id, supp_abbr } = this.suppList.find(({ supp_id }) => supp_id === Number(subItem)) || {};
              temp.push({ value: supp_id, label: supp_abbr });
            }
          });
        } else if (item.inquiryFactory) {
          let { supp_id, supp_abbr } = this.suppList.find(({ supp_id }) => supp_id === Number(item.inquiryFactory)) || {};
          temp.push({ value: supp_id, label: supp_abbr });
        }
        this.$set(item, 'tempSupp', temp);
        this.$set(item, 'tempSize', item['tempSupp'].length);
      });
    },
    clearDyjData() {
      this.partSuppList = [];
      this.subTableData = [];
      this.computedSubTableData = {};
      this.subTableData2 = [];
      this.computedSubTableData2 = {};
    },
    //获取咨询清单报价四张子表数据
    getDyjData(consult_part_id) {
      const partData = this.consultFrom.consult_part_list.find(x => x.consult_part_id === consult_part_id);
      this.otherTableData1 = (partData && partData.rqtaMtrlList) || []; // 原材料
      this.otherTableData2 = (partData && partData.rqtaAuxmtrlList) || []; // 辅助材料
      this.otherTableData3 = (partData && partData.inquWagesList) || []; // 人工工资
      this.otherTableData4 = (partData && partData.rqtaPartList) || []; // 配件信息
      this.computedSubTableData = (partData && partData.rqtaProjet) || {}; // 最终核价
      this.subTableData = (partData && partData.rqtaPricingList) || []; // DYJ核价汇总
      if (consult_part_id) {
        //工厂核价汇总下拉列表
        this.partSuppList = this.consultFrom.consult_part_list.find(x => x.consult_part_id === consult_part_id).tempSupp;
        if (!this.partSuppList) {
          this.subTableData = [];
        }
      } else {
        this.supp_id = null;
        this.partSuppList = [];
      }
      this.supp_id = null;
      this.subTableData2 = [];
      this.computedSubTableData2 = {};
    },
    //更换工厂
    getAuditSummary(supp_id) {
      //找到是那一条子表
      let find = this.consultFrom.consult_part_list.find(x => x.consult_part_id === this.consult_part_id);
      if (supp_id && find) {
        //过滤出工厂核价汇总
        this.subTableData2 = this.consultFrom.rqtaPricingList.filter(x => x.consult_part_id === find.consult_part_id && x.consullt_part_supp_id === supp_id);
        //过滤出最终汇总
        let filter = this.consultFrom.rqtaProjetList.filter(x => x.consult_part_id === find.consult_part_id && x.consullt_part_supp_id === supp_id);
        if (filter.length > 0) {
          this.computedSubTableData2 = filter[0];
        }
      } else {
        this.subTableData2 = [];
        this.computedSubTableData2 = {};
      }
    },
    //计算工厂核价汇总
    changePricing(row) {
      row.includtax_money = new BigNumber(row.pricing_price * row.tax_points).toFixed(4);
      row.taxamount_total = new BigNumber(row.includtax_money - row.pricing_price).toFixed(4);
      //工资回显至子表对应工厂的价格里
      if (row.pricing_name === '工资') {
        let find = this.consultFrom.consult_part_list.find(x => x.consult_part_id === row.consult_part_id);
        let findIndex = find['tempSupp'].findIndex(x => x.value === row.consullt_part_supp_id);
        this.$set(find['tempPrice'], findIndex, row.pricing_price);
      }
      //计算最终汇总表
      this.changeProject();
    },
    changeProject() {
      //除了其他辅料金额的合计
      let includtax_money_sum = objectArrayReduce(this.subTableData2, 'includtax_money');
      this.subTableData2.forEach(item => {
        if (item.pricing_name === '其他辅料金额(纸箱,塑料袋,电绣)') {
          includtax_money_sum -= item.includtax_money;
        }
      });
      //计算利润金额 = 除了其他辅料金额的合计 * 利润百分比
      this.computedSubTableData2.profit_money = new BigNumber(includtax_money_sum).times(this.computedSubTableData2.profit_percentage).toFixed(4);
      //计算含税价格 = 除了其他辅料金额的合计 + 计算利润金额
      this.computedSubTableData2.includtax_money = new BigNumber(includtax_money_sum).plus(this.computedSubTableData2.profit_money).toFixed(4);
      //计算毛利率 = 除了其他辅料金额的合计 / 获取成本价合计
      //获取成本价合计
      let pricing_price_sum = objectArrayReduce(this.subTableData2, 'pricing_price');
      this.computedSubTableData2.gross_margin = new BigNumber(includtax_money_sum).div(pricing_price_sum).toFixed(4);
    },
    // 保存
    saveInfo() {
      //非未报价状态下判断必填
      if (this.consultFrom.table_price_status !== 0) {
        let flag = 0;
        this.consultFrom.consult_part_list.forEach(item => {
          //工厂必须都填
          let isSupp = item.tempSupp.find(item2 => !item2);
          if (isSupp) {
            flag++;
          }
          //价格至少填一个
          let isPrice = item.tempPrice.find(item2 => item2);
          if (!isPrice) {
            flag++;
          }
        });
        if (flag) {
          return this.$message.warning('询价工厂/价格必填！');
        }
      }
      this.consultFrom.consult_part_list.forEach(item => {
        //格式化工厂/价格/时间
        let temp1Price = item.tempPrice;
        let temp2Supp = Array.from(item.tempSupp, ({ value }) => value);
        let temp3Time = item.tempTime;
        let tempSup = [];
        let tempPri = [];
        let tempTim = [];
        temp2Supp.forEach((item, index) => {
          if (item) {
            tempSup.push(item);
            tempPri.push(temp1Price[index] ? temp1Price[index] : '');
            tempTim.push(temp3Time[index] ? temp3Time[index] : '');
          }
        });
        item['inquiryFactory'] = tempSup.join(',');
        item['inquiryFactoryPrice'] = tempPri.join(',');
        item['inquiryFactoryTime'] = tempTim.join(',');
        item.duty_stff_ids = item.duty_stff_ids.join(',');
        item.tempSupp = null;
        item.tempPrice = null;
        item.tempTime = null;
        item.tempSize = null;
        item.del_FactoryIds = item.del_FactoryIds
          .split(',')
          .filter(value => !item.inquiryFactory.split(',').includes(value))
          .join(',');
      });
      this.consultFrom.consult_part_list = this.consultFrom.consult_part_list.concat(this.destroyFlag);
      post(ConsultAPI.editConsult, this.consultFrom).then(({ data }) => {
        if (data.code === 0) {
          this.$message.success('保存成功!');
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.initData();
        } else {
          let mg = data.msg;
          let tp = 'error';
          this.$message.error({ message: mg, type: tp });
        }
      });
    },
    //获取表单信息
    getConsultInfo() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      get(ConsultAPI.getConsultById, { consult_id: props.form_id }).then(({ data }) => {
        if (data.code === 0) {
          this.consultFrom = data.data.form;
          if (this.consultFrom.price_id === 0) this.consultFrom.price_id = null;
          this.consultFrom.consult_part_list.forEach((item, index) => {
            if (item.table_price === 0) item.table_price = null;
            if (item.quote_price === 0) item.quote_price = null;
            if (item.ack_price === 0) item.ack_price = null;
            if (item.ack_time === 0) item.ack_time = null;
            if (item.duty_stff_ids) {
              item.duty_stff_ids = item.duty_stff_ids.split(',').map(Number);
            } else {
              item.duty_stff_ids = [];
            }
            //key值重置
            item.key = index;
          });
          this.partListCopy = JSON.parse(JSON.stringify(this.consultFrom.consult_part_list));
          this.handleSubTableData();
          this.prodNoList = data.data.prodNoList;
          this.stffForm.dept_name = this.consultFrom.dept_name;
          this.stffForm.dept_team_name = this.consultFrom.dept_team_name;
          this.stffForm.stff_name = this.consultFrom.stff_name;
          this.stffForm.user_id = this.consultFrom.user_id;
          this.stffForm.dept_id = this.consultFrom.dept_id;
          this.stffForm.stff_id = this.consultFrom.stff_id;
          this.department = data.data.department;
          this.logUser = this.$cookies.get('userInfo');
          this.btn = data.data.btn;
          this.loadingFlag = false;
          if (this.consultFrom.subItem) {
            this.subItemList = JSON.parse(this.consultFrom.subItem);
          }
          //获取人员
          this.getStff();
        } else {
          let mg = data.msg;
          let tp = 'error';
          this.$message.error({ message: mg, type: tp });
        }
      });
    },
    //子组件传值更改删除list
    updateDestroyFlag(val) {
      this.destroyFlag = this.destroyFlag.concat(val);
    },
    // 转换报价状态
    getPriceStatusName(val) {
      if (val === 0) {
        return { name: '未报价', type: 'info' };
      } else if (val === 1) {
        return { name: '报价中', type: 'warning' };
      } else {
        return { name: '已报价', type: 'success' };
      }
    },
    //获取人员
    getStff() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffList = res.data.data;
            let newStffList = [];
            if (this.consultFrom.dept_name === '采购一部') {
              for (let i = 0; i < this.stffList.length; i++) {
                //采购一部
                if (this.stffList[i].dept_id === 6) {
                  newStffList.push(this.stffList[i]);
                }
              }
            } else if (this.consultFrom.dept_name === '采购二部') {
              for (let i = 0; i < this.stffList.length; i++) {
                //采购二部
                if (this.stffList[i].dept_id === 7) {
                  newStffList.push(this.stffList[i]);
                }
              }
            }
            this.stffList = newStffList;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //获取部门信息
    getDept() {
      get(deptAPI.getAllDeptsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.deptList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(err => console.error(err));
    },
    //获取供应商信息
    getSupp() {
      get(suppAPI.getSuppsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.suppList = res.data.data;
            //获取数据
            this.getConsultInfo();
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //一键下发
    distribute() {
      if (this.consultFrom.dept_name === '采购二部') {
        this.popUpShow = true;
      } else {
        const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
        get(ConsultAPI.updatetablePricestatus, { consult_id: props.form_id })
          .then(({ data }) => {
            if (data.code === 0) {
              this.initData();
              return this.$message.success('下发成功！');
            }
          })
          .catch(res => {
            this.$message.error(res.data.msg);
          });
      }
    },
    // 确认下发
    submitPa(checkStffIds) {
      if (checkStffIds.length === 0) {
        this.$message({ type: 'warning', message: '请至少勾选一个下发人员！' });
      } else {
        const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
        get(ConsultAPI.updatetablePricestatus, { consult_id: props.form_id, stffIds: checkStffIds.join(',') })
          .then(({ data }) => {
            if (data.code === 0) {
              this.initData();
              this.popUpShow = false;
              return this.$message.success('下发成功！');
            }
          })
          .catch(res => {
            this.$message.error(res.data.msg);
          });
      }
    },
    //提交
    subToBoss() {
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          get(ConsultAPI.subToBoss, { consult_id: this.consultFrom.consult_id, user_id: this.logUser.user_id })
            .then(res => {
              if (res.data.code === 0) {
                this.$message.success('提交成功！');
                this.buttonRefresh();
              }
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(() => {});
    }
  }
};
</script>

<style scoped>
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}

.vd_export2 {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 150px;
}

.el-checkbox-group ::v-deep.el-checkbox {
  width: 90px;
}
</style>
